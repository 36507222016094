













































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import ProductType from '@/models/product/ProductType'
import { Watch } from 'vue-property-decorator'
import { map } from 'lodash'
import { vxm } from '@/store'

@Component({
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private listViewKey = 0

  private tabModel = 0
  private tabs = []
  private categories: number[] = []
  private headers = []

  private topActions = []
  private rowActions = []

  private onlyMyShopCheckbox = false
  private formDialog = false
  private formItem: ProductType = null
  private isEdit = false

  private loadingData = true

  created() {
    this.$axios.get(this.url + '/initial-data').then((response) => {
      const data = response.data

      this.tabs = map(data.productTypeCategories, 'name')
      this.categories = map(data.productTypeCategories, 'id')
      this.loadingData = false

      this.routeToParams()
    })

    this.rowActions = [
      {
        id: 'edit',
        visibility: (item) => {
          return !item.isSystem
        },
        click: (parent, item) => {
          this.processForm(item)
        },
      },
      {
        id: 'delete',
        visibility: (item) => {
          return !item.isSystem
        },
      },
    ]
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private submitForm(): void {
    if (!this.form.validate()) {
      return
    }
    if (this.isEdit) {
      this.$axios
        .put(this.url + `/${this.formItem.id}`, this.formItem)
        .then(() => {
          this.formDialog = false
          this.routeToParams()
          this.listViewKey += 1
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    } else {
      this.$axios
        .post(this.url, this.formItem)
        .then(() => {
          this.formDialog = false
          this.routeToParams()
          this.listViewKey += 1
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private processForm(item: Record<string, unknown> = null): void {
    this.formItem = new ProductType(item)
    this.formItem.category = this.formItem.category || this.categories[this.tabModel]
    this.isEdit = !!this.formItem?.id
    this.formDialog = true
  }

  private setTopActions() {
    this.topActions = []
    this.topActions.push({
      id: 'new',
      template: 'new',
      label: 'c:product-type-list-view:New product type',
      click: () => {
        this.processForm()
      },
    })
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'c:product-type-list-view:Name',
        value: 'name',
        filter: { disable: true },
      },
      {
        text: 'c:product-type-list-view:Is stockable product',
        value: 'useStock',
        sortable: false,
        filter: { disable: true },
      },
      {
        text: 'c:product-type-list-view:System default',
        value: 'isSystem',
        sortable: false,
        filter: { disable: true },
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]
  }

  @Watch('$route', { deep: true })
  private routeToParams() {
    if (this.$route.query.category !== undefined) {
      this.onTabChange(this.categories.indexOf(+this.$route.query.category))
    } else {
      this.onTabChange(0)
    }

    if (this.$route.query.onlyMyShop !== undefined) {
      this.onlyMyShopCheckbox = this.$route.query.onlyMyShop.toString() === 'true'
      localStorage.setItem(this.onlyMyShopKey, this.onlyMyShopCheckbox ? '1' : '')
    } else {
      this.onlyMyShopCheckbox = !!localStorage.getItem(this.onlyMyShopKey)
    }

    this.setHeaders()
    this.setTopActions()
  }

  @Watch('onlyMyShopCheckbox')
  private onOnlyMyShopChanged() {
    localStorage.setItem(this.onlyMyShopKey, this.onlyMyShopCheckbox ? '1' : '')
  }

  @Watch('tabModel')
  private onTabModelChanged() {
    this.onlyMyShopCheckbox = !!localStorage.getItem(this.onlyMyShopKey)
  }

  private onTabChange(tabIndex) {
    this.tabModel = tabIndex
  }

  private get url() {
    return '/v4/site/products/types'
  }

  private get query() {
    return {
      category: this.categories[this.tabModel],
      onlyMyShop: this.onlyMyShopCheckbox,
      includeShop: true,
    }
  }

  private get onlyMyShopKey() {
    return 'product-type-list-view-only-shop-' + this.tabModel
  }
}
